import styled from 'styled-components';
import tw from 'tailwind.macro';

export const TitleSection = styled.div`
  ${tw`flex flex-col w-full`};
`;

export const Title = styled.h2`
  ${tw`text-2xl font-bold text-green-800 w-full text-left`};
  ${({ center }) => center && tw`text-center`};
`;

export const SubTitle = styled.h4`
  ${tw`mb-6 text-xs text-blue-500 w-full text-left`};
  ${({ center }) => center && tw`text-center`};
`;

export const Separator = styled.h2`
  ${tw`relative w-2 h-8 mb-6 -mt-2`};
  ${({ center }) => center && tw`mx-auto`};

  &:before {
    content: '';
    ${tw`bg-green-700 h-full w-px absolute left-0`};
  }

  &:after {
    content: '';
    ${tw`bg-blue-500 h-6 w-px absolute ml-1`};
  }
`;
